import React, { useEffect } from 'react'
import PageContent from 'components/common/PageContent'
import ContentHeader from 'components/common/PageContent/ContentHeader'
import SelectedOrdersList from './SelectedOrdersList'
import CurrentShipmentsList from './CurrentShipmentsList'
import ShipmentDetailsForm from './ShipmentDetailsForm'
import { connect } from 'react-redux'
import {
  markAsPrintedStart,
  fetchShipmentListStart,
  voidShipmentStart,
} from 'store/shipment/actions'
import './style.scss'
import { showModal, resetModals } from 'store/modal/actions'
import { MODAL_PENDING } from 'components/common/Modal'
import * as PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { fetchShipmentOrdersStart } from 'store/orderProcess/actions'

const OrdersCreateShipmentPage = (props) => {
  const {
    shipments,
    user,
    isMarkedAsPrinted,
    shipmentOrders,

    markAsPrinted,
    fetchShipmentList,
    showModal,
    fetchShipmentOrdersStart,
    voidShipment,
  } = props

  const { orderIds } = useParams()

  const orders = orderIds.split(',')

  const fetchShipmentsQuiet = () => {
    fetchShipmentList({
      params: {
        orders_ids: orders,
      },
    })
  }

  const onMount = () => {
    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Loading...</div>,
    })
    fetchShipmentList({
      params: {
        orders_ids: orders,
      },
    })
    fetchShipmentOrdersStart({
      params: {
        'filters[order_ids]': orders,
      },
    })
  }

  const onMarkAsPrintedSuccess = () => {
    if (isMarkedAsPrinted) {
      fetchShipmentList({
        params: {
          orders_ids: orders,
        },
      })
    }
  }

  const markBolAsPrinted = (item) => {
    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Marking as printed...</div>,
    })
    markAsPrinted({
      data: {
        shipping_bol_id: item.shipping_bol_id,
        orders_ids: orders,
        user: user.user.staffLogin.username,
      },
    })
  }

  const voidShipmentBol = (item) => {
    showModal({
      name: MODAL_PENDING,
      content: <div className="text">Voiding Bol...</div>,
    })
    voidShipment({
      data: {
        id: item.id,
        orders_ids: orders,
      },
    })
  }

  useEffect(onMarkAsPrintedSuccess, [isMarkedAsPrinted])
  useEffect(onMount, [])

  return (
    <PageContent className="orders-create-shipment-page">
      <ContentHeader
        title="Create Shipment"
        description="Add Information For A New Shipment"
      />
      {!!shipmentOrders.length && (
        <>
          <SelectedOrdersList orders={shipmentOrders} />
          <ShipmentDetailsForm
            orders={shipmentOrders}
            fetchShipments={fetchShipmentsQuiet}
          />
        </>
      )}

      {!!shipmentOrders.length && !!shipments.length && (
        <CurrentShipmentsList
          shipments={shipments}
          markAsPrinted={markBolAsPrinted}
          voidBol={voidShipmentBol}
        />
      )}
    </PageContent>
  )
}

OrdersCreateShipmentPage.propTypes = {
  shipments: PropTypes.array,
}

const mapStateToProps = (state) => ({
  shipments: state.shipment.items,
  user: state.auth.user,
  isMarkedAsPrinted: state.shipment.isMarkedAsPrinted,
  shipmentOrders: state.orderProcess.shipment,
})

const mapDispatchToProps = {
  fetchShipmentList: fetchShipmentListStart,
  fetchShipmentOrdersStart,
  markAsPrinted: markAsPrintedStart,
  voidShipment: voidShipmentStart,
  showModal,
  resetModals,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrdersCreateShipmentPage)

import React from 'react'
import TableList from 'components/common/TableList'
import { dateToFormat } from 'helpers/index'
import Tooltip from 'components/common/Tooltip'
import { MAPPER_CARRIER_LOGO } from 'constants/mappers'
import ContentSection from 'components/common/PageContent/ContentSection'
import { GrNotes } from 'react-icons/gr'
import { showModal, closeModal } from 'store/modal/actions'
import { connect } from 'react-redux'

const SelectedOrdersList = (props) => {
  const { orders, showModal, closeModal } = props

  const getColumns = () => [
    {
      id: 1,
      label: 'Order',
      value: 'orderId',
    },
    {
      id: 2,
      label: 'Carrier',
      value: 'carrier',
    },
    {
      id: 3,
      label: 'Cases',
      value: 'case',
    },
    {
      id: 4,
      label: 'Pallets',
      value: 'palletsQty',
    },
    {
      id: 5,
      label: 'Weight',
      value: 'weight',
    },
    {
      id: 6,
      label: 'Customer',
      value: 'customer',
    },
    {
      id: 7,
      label: 'Order Notes',
      value: 'orderNotes',
    },
    {
      id: 8,
      label: 'Shipping Date',
      value: 'shippingDate',
    },
  ]

  const showOrderNotes = (order) => {
    showModal({
      name: 'modalOrderNotes',
      onClose: () => {
        closeModal('modalOrderNotes')
      },
      order: order,
    })
  }

  const getNormalizedItems = () =>
    orders.map((item) => {
      let orderCasesQty = item.entity.order.total_products_quantity
      const weight = item.entity.order.order_pallets
        .map((orderPallet) => orderPallet.pallet_weight)
        .reduce((acc, weight) => acc + weight, 0)
      const shippingDate = item.entity.order.shipping_date
        ? dateToFormat(item.entity.order.shipping_date, 'MM/DD/YYYY')
        : dateToFormat(item.entity.order.pickup_date, 'MM/DD/YYYY')

      return {
        id: item.id,
        orderId: item.entity.order.orders_id,
        carrier: (
          <Tooltip
            hoverTarget={MAPPER_CARRIER_LOGO[item.entity.carrier.id]}
            sideText={item.entity.carrier.name}
            extraTargetClassName="carrier-icon-target"
            extraBubbleClassName="for-carrier-logo"
          />
        ),
        palletsQty: item.entity.order.order_pallets.length,
        case: orderCasesQty,
        weight: weight.toFixed(2),
        customer: item.entity.order.customers_name,
        shippingDate: shippingDate,
        orderNotes:
          item.entity.order.shipping_notes ||
          item.entity.order.warehouse_notes ? (
            <GrNotes
              className="notes-icon"
              onClick={() => {
                showOrderNotes(item.entity.order)
              }}
            />
          ) : (
            ''
          ),
      }
    })

  return (
    <ContentSection>
      <div className="header">
        <div className="header__title">Selected Orders</div>
      </div>
      <hr />
      <TableList
        className="list"
        rows={getNormalizedItems()}
        columns={getColumns()}
      />
    </ContentSection>
  )
}

const mapDispatchToProps = {
  showModal,
  closeModal,
}

export default connect(null, mapDispatchToProps)(SelectedOrdersList)
